import { getType } from '@anm/helpers/saga/typesafe-actions';
import { Reducer } from 'redux';

import { subscriptionActions } from '..';
import { authActions } from '../../auth';
import { ChangeSubscriptionState, SubscriptionActions } from '../types';

const initialChangeSubscriptionState: ChangeSubscriptionState = {
  data: null,
  error: null,
  isError: false,
  isPending: false
};

const changeSubscriptionReducer: Reducer<typeof initialChangeSubscriptionState, SubscriptionActions> = (
  state = initialChangeSubscriptionState,
  action
) => {
  switch (action.type) {
    case getType(authActions.logoutAsync.success):
    case getType(subscriptionActions.resetSubscriptionChangeState): {
      return initialChangeSubscriptionState;
    }

    case getType(subscriptionActions.changeSubscriptionAsync.request): {
      return {
        ...state,
        isError: false,
        isPending: true
      };
    }

    case getType(subscriptionActions.changeSubscriptionAsync.success): {
      return {
        ...state,
        data: action.payload,
        isError: false,
        isPending: false
      };
    }

    case getType(subscriptionActions.changeSubscriptionAsync.failure): {
      return {
        ...state,
        error: action.payload,
        isError: true,
        isPending: false
      };
    }

    default:
      return state;
  }
};

export default changeSubscriptionReducer;
