import { Reducer } from 'redux';
import { getType } from '@anm/helpers/saga/typesafe-actions';

import { subscriptionActions } from '..';
import { ListState, SubscriptionActions } from '../types';

const initialListState: ListState = {
  data: null,
  error: null,
  isError: false,
  isPending: false
};

const listReducer: Reducer<typeof initialListState, SubscriptionActions> = (
  state = initialListState,
  action
) => {
  switch (action.type) {
    case getType(subscriptionActions.fetchSubscriptionListAsync.request): {
      return {
        ...state,
        isError: false,
        isPending: true
      };
    }
    case getType(subscriptionActions.fetchSubscriptionListAsync.success): {
      const { payload: subscriptionList } = action;

      return {
        ...state,
        data: subscriptionList,
        isError: false,
        isPending: false
      };
    }
    case getType(subscriptionActions.fetchSubscriptionListAsync.failure): {
      const { payload: newError } = action;

      return {
        ...state,
        error: newError,

        isError: true,
        isPending: false
      };
    }
    default:
      return state;
  }
};

export default listReducer;
