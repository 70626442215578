import { Reducer } from 'redux';
import { getType } from '@anm/helpers/saga/typesafe-actions';

import { subscriptionActions } from '../..';
import { StripeTokenState, SubscriptionActions } from '../../types';

const initialStripeTokenState: StripeTokenState = {
  data: null,
  formData: null,
  error: null,
  isError: false,
  isPending: false
};

const stripeTokenReducer: Reducer<StripeTokenState, SubscriptionActions> = (
  state = initialStripeTokenState,
  action
) => {
  switch (action.type) {
    case getType(subscriptionActions.createStripeTokenAsync.request): {
      return {
        ...initialStripeTokenState,
        formData: action.payload,
        isError: false,
        isPending: true
      };
    }

    case getType(subscriptionActions.createStripeTokenAsync.success): {
      return {
        ...state,
        data: action.payload,
        isError: false,
        isPending: false
      };
    }

    case getType(subscriptionActions.createStripeTokenAsync.failure): {
      return {
        ...state,
        error: action.payload.error,
        isError: true,
        isPending: false
      };
    }

    default:
      return state;
  }
};

export default stripeTokenReducer;
