import { getType } from '@anm/helpers/saga/typesafe-actions';
import { Reducer } from 'redux';

import { subscriptionActions } from '../..';
import { CardUpdateState, SubscriptionActions } from '../../types';

const initialCardUpdateState: CardUpdateState = {
  data: null,
  isError: false,
  isPending: false
};

const cardUpdateReducer: Reducer<CardUpdateState, SubscriptionActions> = (state = initialCardUpdateState, action) => {
  switch (action.type) {
    case getType(subscriptionActions.resetUpdateCardState): {
      return initialCardUpdateState;
    }
    case getType(subscriptionActions.updateCardAsync.request): {
      return {
        ...state,
        isError: false,
        isPending: true
      };
    }

    case getType(subscriptionActions.updateCardAsync.success): {
      return {
        ...state,
        data: action.payload,
        isError: false,
        isPending: false
      };
    }

    case getType(subscriptionActions.updateCardAsync.failure): {
      return {
        ...state,
        error: action.payload,
        isError: true,
        isPending: false
      };
    }
    default:
      return state;
  }
};

export default cardUpdateReducer;
