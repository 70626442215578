import { combineReducers } from 'redux';

import calculateReducer from './calculateReducer';
import cancelSubscriptionReducer from './cancelSubscriptionReducer';
import cardDeleteReducer from './card/cardDeleteReducer';
import cardUpdateReducer from './card/cardUpdateReducer';
import changeSubscriptionReducer from './changeSubscriptionReducer';
import historyReducer from './historyReducer';
import listReducer from './listReducer';
import redeemCouponReducer from './redeemCouponReducer';
import stripeTokenReducer from './stripe/stripeTokenReducer';

const cardReducer = combineReducers({ update: cardUpdateReducer, delete: cardDeleteReducer });
const stripeReducer = combineReducers({ token: stripeTokenReducer });

const subscriptionReducer = combineReducers({
  list: listReducer,
  card: cardReducer,
  stripe: stripeReducer,
  history: historyReducer,
  calculate: calculateReducer,
  redeemCoupon: redeemCouponReducer,
  subscriptionChange: changeSubscriptionReducer,
  cancelSubscription: cancelSubscriptionReducer
});

export default subscriptionReducer;
