import asyncEntityHandlers from '@anm/helpers/redux/asyncEntityHandlers';
import reduceFactory from '@anm/helpers/redux/reduceFactory';
import { Reducer } from 'redux';

import { subscriptionActions } from '../..';
import { CardDeleteState, SubscriptionActions } from '../../types';

const initialState: CardDeleteState = {
  isError: false,
  isPending: false
};

const cardDeleteReducer: Reducer<CardDeleteState, SubscriptionActions> = (state = initialState, action) => {
  switch (action.type) {
    default:
      return reduceFactory(initialState, {
        ...asyncEntityHandlers<CardDeleteState>(subscriptionActions.deleteCardAsync)
      })(state, action);
  }
};

export default cardDeleteReducer;
