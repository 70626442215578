import { SubscriptionDetails, SubscriptionName } from '@anm/api/modules/subscription';
import logger from '@anm/helpers/logger';

import getOriginSubscriptionName from '../getOriginSubscriptionName';

const findSubscription = (subscriptionList: SubscriptionDetails[]) => (id: SubscriptionName) => {
  const subscriptionDetails = subscriptionList.find(
    ({ name }) => getOriginSubscriptionName(id) === getOriginSubscriptionName(name)
  );

  if (!subscriptionDetails) {
    logger(`Can not to find subscription with id: ${id}`);
    return null;
  }

  return subscriptionDetails;
};

export default findSubscription;
