import api from '@anm/api';
import { CalculateParams, ChangeSubscriptionData, UpdateCardData } from '@anm/api/modules/subscription';
import isValuesEmpty from '@anm/helpers/is/isValuesEmpty';
import asyncEntity from '@anm/helpers/redux/asyncEntity';
import { call, cancel, fork, put, select, take } from '@anm/helpers/saga/effects';
import { takeType } from '@anm/helpers/saga/typesafe-actions';
import * as stripe from '@anm/helpers/stripe';
import { appNotificationActions } from '@anm/store/modules/appNotifications';
import { Task } from 'redux-saga';
import { StripeFormData } from 'types/Stripe';

import { userSelectors } from '../user';

import { subscriptionActions, subscriptionSelectors } from '.';
import { ChangeSubscriptionProps, EditCreditCardProps } from './types';

const changeSubscriptionRequest = asyncEntity(
  subscriptionActions.changeSubscriptionAsync,
  (params: ChangeSubscriptionData) => api().subscription.changeSubscription(params)
);

const fetchSubscriptionList = asyncEntity(subscriptionActions.fetchSubscriptionListAsync, () =>
  api().subscription.getSubscriptionList()
);

const updateCardAsync = asyncEntity(subscriptionActions.updateCardAsync, (data: UpdateCardData) =>
  api().subscription.updateCard(data)
);
const deleteCardAsync = asyncEntity(subscriptionActions.deleteCardAsync, () => api().subscription.deleteCreditCard());

const createStripeTokenAsync = asyncEntity(subscriptionActions.createStripeTokenAsync, (formData: StripeFormData) =>
  stripe.createToken(formData)
);

const fetchSubscriptionHistory = asyncEntity(subscriptionActions.fetchSubscriptionHistoryAsync, () =>
  api().subscription.getSubscriptionHistory()
);

const cancelSubscriptionRequest = asyncEntity(subscriptionActions.cancelSubscriptionAsync, () =>
  api().subscription.cancelSubscription()
);

const fetchSubscriptionCalculate = asyncEntity(
  subscriptionActions.fetchSubscriptionCalculateAsync,
  (params: CalculateParams) => api().subscription.getSubscriptionCalculate(params)
);

const redeemCouponAsync = asyncEntity(subscriptionActions.redeemCouponAsync, (coupon: string) =>
  api().subscription.activateCoupon({ code: coupon })
);

function* changeSubscription({ email, stripe, name, period, couponCode }: ChangeSubscriptionProps) {
  const changeSubscriptionData = { email, name, couponCode, period };

  if (stripe && !isValuesEmpty(stripe)) {
    yield* put(subscriptionActions.createStripeToken(stripe));
    const { type } = yield* take([
      takeType(subscriptionActions.createStripeTokenAsync.success),
      takeType(subscriptionActions.createStripeTokenAsync.failure)
    ]);

    if (type === takeType(subscriptionActions.createStripeTokenAsync.failure)) return;

    const token: ReturnType<typeof subscriptionSelectors.selectStripeToken> = yield select(
      subscriptionSelectors.selectStripeToken
    );

    yield* call(changeSubscriptionRequest, {
      token,
      ...changeSubscriptionData
    });
  } else {
    yield* call(changeSubscriptionRequest, changeSubscriptionData);
  }
}

function* watchCancelSubscription() {
  while (true) {
    yield* take(takeType(subscriptionActions.cancelSubscription));

    yield* call(cancelSubscriptionRequest);
  }
}

function* watchChangeSubscription() {
  while (true) {
    const { payload }: ReturnType<typeof subscriptionActions.changeSubscription> = yield* take(
      takeType(subscriptionActions.changeSubscription)
    );

    yield* call(changeSubscription, payload);
  }
}

function* updateStripeCard({ stripe }: EditCreditCardProps) {
  yield* put(subscriptionActions.createStripeToken(stripe));
  yield* take(takeType(subscriptionActions.createStripeTokenAsync.success));

  const token = yield* select(subscriptionSelectors.selectStripeToken);

  if (!token) return;

  yield* fork(updateCardAsync, { token });
}

const getEditCreditCardWatcher = () => {
  let lastTask: Task;
  return function* watchEditCreditCard() {
    while (true) {
      const { payload } = yield* take(takeType(subscriptionActions.editCreditCard));

      if (lastTask) {
        yield cancel(lastTask);
      }

      lastTask = yield* fork(updateStripeCard, payload);
    }
  };
};

function* watchFetchSubscriptionData() {
  while (true) {
    yield* take(takeType(subscriptionActions.fetchSubscriptionList));
    yield* call(fetchSubscriptionList);
  }
}

function* watchCreateStripeToken() {
  while (true) {
    const { payload } = yield* take(takeType(subscriptionActions.createStripeToken));
    yield* call(createStripeTokenAsync, payload);
  }
}

function* watchFetchSubscriptionHistory() {
  while (true) {
    yield take(takeType(subscriptionActions.fetchSubscriptionHistory));
    const teamRole = yield* select(userSelectors.selectTeamRole);

    if (!teamRole || teamRole === 'OWNER') yield* call(fetchSubscriptionHistory);
  }
}

function* watchDeleteCardSuccess() {
  while (true) {
    yield take(takeType(subscriptionActions.deleteCardAsync.success));
    yield* put(subscriptionActions.fetchSubscriptionHistory());
  }
}
function* watchDeleteCard() {
  while (true) {
    yield take(takeType(subscriptionActions.deleteCard));
    yield* fork(watchDeleteCardSuccess);
    yield* call(deleteCardAsync);
  }
}

function* watchRedeemCoupon() {
  while (true) {
    const { payload } = yield* take(takeType(subscriptionActions.redeemCoupon));

    yield* call(redeemCouponAsync, payload);
  }
}

const getFetchSubscriptionCalculateWatcher = () => {
  let lastTask: Task;
  return function* watchFetchSubscriptionCalculate() {
    while (true) {
      const { payload } = yield* take(takeType(subscriptionActions.fetchSubscriptionCalculate));

      if (lastTask) {
        yield* cancel(lastTask);
      }
      lastTask = yield* fork(fetchSubscriptionCalculate, payload);
    }
  };
};

function* watchFailures() {
  while (true) {
    const {
      payload: { code, message }
    } = yield* take([takeType(subscriptionActions.cancelSubscriptionAsync.failure)]);

    if (code && message) {
      yield* put(
        appNotificationActions.notify({
          type: 'error',
          timeout: 15000,
          title: message
        })
      );
    }
  }
}

const subscriptionWatchers = () => [
  call(watchFailures),
  call(watchDeleteCard),
  call(watchRedeemCoupon),
  call(watchCreateStripeToken),
  call(watchCancelSubscription),
  call(watchChangeSubscription),
  call(getEditCreditCardWatcher()),
  call(watchFetchSubscriptionData),
  call(watchFetchSubscriptionHistory),
  call(getFetchSubscriptionCalculateWatcher())
];

export default subscriptionWatchers;
