import { Reducer } from 'redux';
import { getType } from '@anm/helpers/saga/typesafe-actions';

import { subscriptionActions } from '..';
import { RedeemCouponState, SubscriptionActions } from '../types';

const initialRedeemCouponState: RedeemCouponState = {
  data: null,
  error: null,
  isError: false,
  isPending: false
};

const redeemCouponReducer: Reducer<
  typeof initialRedeemCouponState,
  SubscriptionActions
> = (state = initialRedeemCouponState, action) => {
  switch (action.type) {
    case getType(subscriptionActions.redeemCouponAsync.request): {
      return {
        ...initialRedeemCouponState,
        isError: false,
        isPending: true
      };
    }

    case getType(subscriptionActions.redeemCouponAsync.success): {
      return {
        ...state,
        data: action.payload,
        isError: false,
        isPending: false
      };
    }

    case getType(subscriptionActions.redeemCouponAsync.failure): {
      return {
        ...state,
        error: action.payload,
        isError: true,
        isPending: false
      };
    }

    default:
      return state;
  }
};

export default redeemCouponReducer;
