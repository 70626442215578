import { b64EncodeUnicode } from '@anm/helpers/string/base64';
import values from 'lodash/fp/values';

const getHashByObject = (obj: {}) =>
  b64EncodeUnicode(
    values<string>(obj)
      .map(v => JSON.stringify(v) || '')
      .map(v => v.replace(/\s*/g, ''))
      .join('')
  );

export default getHashByObject;
