import { STRIPE_LIB_URL } from '@anm/constants/services';
import getHashByObject from '@anm/helpers/get/getHashByObject';
import { StripeCreateTokenError, StripeCreateTokenResponse, StripeFormData } from 'Stripe';

import { injectGlobal } from '../stateful/injectGlobal';
import waitFor from '../waitFor';

const IDEMPOTENCY_HEADER_NAME = 'Idempotency-Key';

const setIdempotencyHeader = async (formData: {}) => {
  const idempotencyKey = getHashByObject(formData);
  const stripe = await waitStripe();

  stripe?._allowedCustomHeaders.push(IDEMPOTENCY_HEADER_NAME);
  stripe?._setCustomHeader(IDEMPOTENCY_HEADER_NAME, idempotencyKey);
};

const setKey = async (key: string) => {
  const stripe = await waitStripe();
  stripe?.setPublishableKey(key);
};

const waitStripe = async () => {
  await waitFor(() => !!window.Stripe?.createToken, 70, 'wait for stripe plugin loaded');
  return window.Stripe;
};

const isStripeResponseError = (
  response: StripeCreateTokenResponse | StripeCreateTokenError
): response is StripeCreateTokenError => {
  return !!(response as StripeCreateTokenError).error;
};

export const createToken = async (formData: StripeFormData) => {
  const stripe = await waitStripe();

  setIdempotencyHeader(formData);

  return new Promise((resolve, reject) =>
    stripe?.createToken(formData, (_, response) => {
      return isStripeResponseError(response) ? reject(response) : resolve(response);
    })
  );
};

type InitStripeProps = {
  key: string;
};

export const injectStripeLib = () => {
  injectGlobal({ head: <script src={STRIPE_LIB_URL} /> });
};

export const initStripe = ({ key }: InitStripeProps) => {
  setKey(key);
};
