import { SubscriptionName } from '@anm/api/modules/subscription';
import { compose } from 'lodash/fp';

type Map = { [key in string]: SubscriptionName };

const trimNumber = (exclude: string) => (name: SubscriptionName) =>
  name.toLowerCase().includes(exclude) ? name : name.replace(/([0-9]+$)|(^[0-9]+)/g, '');

const trimWave = (name: SubscriptionName) => name.replace(/^Wave/g, '');
const getFirstWord = (name: string) => name.split(/(?=[A-Z])/)[0];
const getOrigin = compose(getFirstWord, trimNumber('team'), trimWave);
const mapOld = (id: SubscriptionName) => (({ Agency: 'WaveBusiness', Pro: 'WaveCreator' } as Map)[getOrigin(id)] || id);

const getOriginSubscriptionName = compose(getOrigin, mapOld);

export default getOriginSubscriptionName;
