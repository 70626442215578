import { SubscriptionDetails, SubscriptionName } from '@anm/api/modules/subscription';
import selectProps from '@anm/helpers/store/selectProps';
import findSubscription from '@anm/helpers/subscription/findSubscription';
import { createSelector } from 'reselect';

import { ApplicationState } from '../../types';

export const selectSubscription = ({ subscription }: ApplicationState) => subscription;
export const selectSubscriptionList = ({ subscription }: ApplicationState) => subscription?.list.data;

export const selectSubscriptionListPending = ({ subscription }: ApplicationState) => subscription?.list.isPending;

export const selectHistoryPending = ({ subscription }: ApplicationState) => subscription?.history?.isPending;

export const selectCancelSubscriptionPending = ({ subscription }: ApplicationState) =>
  subscription?.cancelSubscription?.isPending;

export const selectCancelSubscriptionError = ({ subscription }: ApplicationState) =>
  subscription?.cancelSubscription?.error;

export const selectHistory = ({ subscription }: ApplicationState) => subscription?.history?.data;

export const selectCard = ({ subscription }: ApplicationState) => subscription?.history?.data?.card;

export const selectCardCharges = ({ subscription }: ApplicationState) => subscription?.history?.data?.charges;

export const selectCardNextInvoice = ({ subscription }: ApplicationState) =>
  subscription?.history?.data?.next_invoice?.amount;

export const selectCardNextInvoiceDate = ({ subscription }: ApplicationState) =>
  subscription?.history?.data?.next_invoice?.date;

export const selectSubscriptionCalculate = ({ subscription }: ApplicationState) => subscription?.calculate?.data;

export const selectStripeToken = ({ subscription }: ApplicationState) => subscription?.stripe?.token.data?.id;

export const selectStripeTokenPending = ({ subscription }: ApplicationState) => subscription?.stripe?.token.isPending;

export const selectStripeTokenCard = ({ subscription }: ApplicationState) => subscription?.stripe?.token.data?.card;

export const selectSubscriptionChange = createSelector(
  selectSubscription,
  subscription => subscription?.subscriptionChange?.data
);

export const selectSubscriptionChangePending = ({ subscription }: ApplicationState) =>
  subscription?.subscriptionChange?.isPending;

export const selectDeleteCardState = ({ subscription }: ApplicationState) => subscription?.card.delete;
export const selectDeleteCardIsPending = ({ subscription }: ApplicationState) => subscription?.card.delete.isPending;

export const selectUpdateCardPending = ({ subscription }: ApplicationState) => subscription?.card.update?.isPending;
export const selectUpdateCardData = ({ subscription }: ApplicationState) => subscription?.card.update?.data;

export const selectSubscriptionChangeErrorStatus = ({ subscription }: ApplicationState) =>
  subscription?.subscriptionChange?.isError;

export const selectSubscriptionCalculatePending = ({ subscription }: ApplicationState) =>
  subscription?.calculate?.isPending;

export const selectSubscriptionByName = createSelector(
  selectSubscriptionList,
  selectProps<SubscriptionName>(),
  (subscriptions: SubscriptionDetails[] | null, planName) => subscriptions && findSubscription(subscriptions)(planName)
);

export const selectSubscriptionHistoryError = ({ subscription }: ApplicationState) => subscription?.history?.error;

export const selectSubscriptionCalculateError = ({ subscription }: ApplicationState) => subscription?.calculate?.error;

export const selectSubscriptionChangeError = ({ subscription }: ApplicationState) =>
  subscription?.subscriptionChange?.error;

export const selectSubscriptionListError = ({ subscription }: ApplicationState) => subscription?.list?.error;

export const selectStripeTokenError = ({ subscription }: ApplicationState) => subscription?.stripe?.token.error;

export const selectCardUpdateError = ({ subscription }: ApplicationState) => subscription?.card?.update.error;

export const selectRedeemCouponError = ({ subscription }: ApplicationState) => subscription?.redeemCoupon?.error;
