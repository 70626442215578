import { ApiError } from '@anm/api';
import { SubscriptionDetails } from '@anm/api/modules/subscription';
import {
  CalculateParams,
  CalculateResponseData,
  CardDetails,
  ChangeSubscriptionData,
  ChangeSubscriptionResponse
} from '@anm/api/modules/subscription/types';
import { createAction, createAsyncAction } from '@anm/helpers/saga/typesafe-actions';
import { StripeCreateTokenError, StripeCreateTokenResponse, StripeFormData } from 'Stripe';

import { ChangeSubscriptionProps, EditCreditCardProps } from './types';

export const fetchSubscriptionCalculate = createAction('wave.video/subscription/FETCH_SUBSCRIPTION_CALCULATE')<
  CalculateParams
>();
export const fetchSubscriptionCalculateAsync = createAsyncAction(
  'wave.video/subscription/FETCH_SUBSCRIPTION_CALCULATE_REQUEST',
  'wave.video/subscription/FETCH_SUBSCRIPTION_CALCULATE_SUCCESS',
  'wave.video/subscription/FETCH_SUBSCRIPTION_CALCULATE_ERROR'
)<CalculateParams, CalculateResponseData, ApiError>();

export const fetchSubscriptionList = createAction('wave.video/subscription/FETCH_SUBSCRIPTION_LIST')();
export const fetchSubscriptionListAsync = createAsyncAction(
  'wave.video/subscription/FETCH_SUBSCRIPTION_LIST_REQUEST',
  'wave.video/subscription/FETCH_SUBSCRIPTION_LIST_SUCCESS',
  'wave.video/subscription/FETCH_SUBSCRIPTION_LIST_ERROR'
)<{}, SubscriptionDetails[], ApiError>();

export const fetchSubscriptionHistory = createAction('wave.video/subscription/FETCH_SUBSCRIPTION_HISTORY')();
export const fetchSubscriptionHistoryAsync = createAsyncAction(
  'wave.video/subscription/FETCH_SUBSCRIPTION_HISTORY_REQUEST',
  'wave.video/subscription/FETCH_SUBSCRIPTION_HISTORY_SUCCESS',
  'wave.video/subscription/FETCH_SUBSCRIPTION_HISTORY_ERROR'
)<{}, CardDetails, ApiError>();

export const editCreditCard = createAction('wave.video/subscription/EDIT_CREDIT_CARD')<EditCreditCardProps>();
export const resetUpdateCardState = createAction('wave.video/subscription/RESET_UPDATE_CARD_STATE')();
export const deleteCard = createAction('wave.video/subscription/DELETE_CARD')();
export const deleteCardAsync = createAsyncAction(
  'wave.video/subscription/DELETE_CARD_REQUEST',
  'wave.video/subscription/DELETE_CARD_SUCCESS',
  'wave.video/subscription/DELETE_CARD_ERROR'
)<{}, 'Updated' | null, ApiError>();
export const updateCardAsync = createAsyncAction(
  'wave.video/subscription/UPDATE_CARD_REQUEST',
  'wave.video/subscription/UPDATE_CARD_SUCCESS',
  'wave.video/subscription/UPDATE_CARD_ERROR'
)<{}, 'Updated' | null, ApiError>();

export const resetSubscriptionChangeState = createAction('wave.video/subscription/RESET_SUBSCRIPTION_CHANGE_STATE')();
export const changeSubscription = createAction('wave.video/subscription/CHANGE_SUBSCRIPTION')<
  ChangeSubscriptionProps
>();
export const changeSubscriptionAsync = createAsyncAction(
  'wave.video/subscription/CHANGE_SUBSCRIPTION_REQUEST',
  'wave.video/subscription/CHANGE_SUBSCRIPTION_SUCCESS',
  'wave.video/subscription/CHANGE_SUBSCRIPTION_ERROR'
)<ChangeSubscriptionData, ChangeSubscriptionResponse, ApiError>();

export const cancelSubscription = createAction('wave.video/subscription/CANCEL_SUBSCRIPTION')();
export const cancelSubscriptionAsync = createAsyncAction(
  'wave.video/subscription/CANCEL_SUBSCRIPTION_REQUEST',
  'wave.video/subscription/CANCEL_SUBSCRIPTION_SUCCESS',
  'wave.video/subscription/CANCEL_SUBSCRIPTION_ERROR'
)<{}, 'Updated' | null, ApiError>();

export const createStripeToken = createAction('wave.video/subscription/CREATE_STRIPE_TOKEN')<StripeFormData>();
export const createStripeTokenAsync = createAsyncAction(
  'wave.video/subscription/CREATE_STRIPE_TOKEN_REQUEST',
  'wave.video/subscription/CREATE_STRIPE_TOKEN_SUCCESS',
  'wave.video/subscription/CREATE_STRIPE_TOKEN_ERROR'
)<StripeFormData, StripeCreateTokenResponse, StripeCreateTokenError>();

export const redeemCoupon = createAction('wave.video/subscription/REDEEM_COUPON')<string>();
export const redeemCouponAsync = createAsyncAction(
  'wave.video/subscription/REDEEM_COUPON_REQUEST',
  'wave.video/subscription/REDEEM_COUPON_SUCCESS',
  'wave.video/subscription/REDEEM_COUPON_ERROR'
)<string, 'Updated', ApiError>();
